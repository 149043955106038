import type { MetaFunction } from "@remix-run/cloudflare";
import { useEffect } from "react";
import { useScrollToAnchor } from "./components/useScrollToAnchor";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TopicPath from "./components/TopicPath";
import Contact2 from "./components/top/Contact2";
import MediumButton from "./components/MediumButton";

export const meta: MetaFunction = () => {
  return [
    { title: "404 Not Found | 飲食店の正社員求人・転職はエフジョブ求人" },
    {
      name: "description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:title",
      content: "404 Not Found | 飲食店の正社員求人・転職はエフジョブ求人",
    },
    {
      property: "og:description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      // NOTE: 関数外で現環境を特定する方法がぱっとわからなかったので、ハードコーディング使用
      // NOTE: 環境関係なく本番のR2を見に行くようにしている
      content: "https://public.fjob-kyujin.com/public/ogimage.png",
    },
    {
      property: "og:url",
      content: "https://fjob-kyujin.com/",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
  ];
};

export default function Result() {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  const linkToTop = () => {
    location.href = "/";
  };

  return (
    <div>
      <Header></Header>
      <TopicPath>
        <li>
          <a href="/">TOP</a>
        </li>
        <li>404 Not Found</li>
      </TopicPath>
      <main>
        <div className="px-6 py-20 md:px-7 md:py-[120px]">
          <div className="md:max-w-[1040px] md:mx-auto md:text-center">
            <h2 className="mb-4 text-2xl font-bold leading-normal md:text-[32px] md:leading-normal">
              ページが見つかりません。
            </h2>
            <p className="text-sm leading-[1.8] md:text-base md:leading-normal">
              お探しのページは移動、または削除された可能性がございます。
            </p>
            <div className="mt-10 text-center [&_button]:w-[280px]">
              <MediumButton
                text="TOPページに戻る"
                color="white"
                handler={linkToTop}
              ></MediumButton>
            </div>
          </div>
        </div>
        <Contact2></Contact2>
      </main>
      <Footer></Footer>
    </div>
  );
}
